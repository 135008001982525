import { serviceBase } from './serviceBase';
import axiosClient from './axiosClient';

export const getClientExistingByRFC = serviceBase({
  request: rfc => axiosClient.post(`/prospects-form/search-client`, { rfc })
});

export const assignClientToQuote = serviceBase({
  request: clientId =>
    axiosClient.post(`/prospects-form/associate-quote/${clientId}`)
});

export const sendToCreateBureauForm = serviceBase({
  request: data => axiosClient.post(`/prospects-form/create-form`, data)
});

export const sendToSubmitForm = serviceBase({
  request: ({ formId, values }) =>
    axiosClient.post(`/prospects-form/submit/${formId}`, values)
});

export const sendToValidateNip = serviceBase({
  request: ({ nip, formId }) =>
    axiosClient.post(`/prospects-form/validate-nip/${formId}`, { nip })
});

export const resendNip = serviceBase({
  request: formId => axiosClient.post(`/prospects-form/send-nip/${formId}`)
});

export const getQuoteInfo = serviceBase({
  request: () => axiosClient.get(`/prospects-form/quote-information`)
});
