import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default values => ({
  ...values,
  startDate: momentLocalTimezone(),
  firstRentDate: momentLocalTimezone()
    .add(1, 'month'),
  gracePeriod: 0,
  endDate: momentLocalTimezone()
    .add(values.term, 'months')
});
