import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { selectIndustriesList } from 'src/store/selectors/catalogSelectors';
import { loadIndustries } from 'src/store/actions/catalogActions';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';

import DialogTransition from 'src/components/DialogTransition';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../../styles';

const LegalClientGeneralDetailDialog = ({
  open,
  client,
  handleClose,
  submit
}) => {
  const industries = useSelector(selectIndustriesList) || [];
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(client), [client]);

  const resetRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit(difference);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    if (open) {
      dispatch(loadIndustries());
    }
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.CLIENT_DETAIL.GENERAL_CLIENT_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.industry && errors.industry)}
                >
                  <Autocomplete
                    label={t('CLIENTS_VIEW.CREATION_FORM.INDUSTRY')}
                    name="industry"
                    options={industries}
                    id="industry"
                    value={industries.find(
                      option => option.name === values.industry
                    )}
                    getOptionLabel={option => {
                      return `${option.id} - ${option.name}`;
                    }}
                    clearOnBlur={false}
                    fullWidth
                    clearIcon={null}
                    onChange={(_e, value) => {
                      setFieldValue('industry', value.name ?? '');
                    }}
                    helperText={touched.industry && t(errors.industry)}
                    error={Boolean(touched.industry && errors.industry)}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                  />
                </FormControl>

                <DatePicker
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.CONSTITUTION_DATE')}
                  value={values.birthDate}
                  onChange={newValue => {
                    setFieldValue('birthDate', moment(newValue));
                  }}
                  error={Boolean(touched.birthDate && errors.birthDate)}
                  renderInput={params => (
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      {...params}
                      sx={{ width: '100%', margin: '16px 0' }}
                      helperText={touched.birthDate && t(errors.birthDate)}
                    />
                  )}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.rfc && errors.rfc)}
                  fullWidth
                  margin="dense"
                  helperText={touched.rfc && t(errors.rfc)}
                  label={t('COMMON.RFC')}
                  name="rfc"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.rfc}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.deedNumber && errors.deedNumber)}
                  fullWidth
                  margin="dense"
                  helperText={touched.deedNumber && t(errors.deedNumber)}
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.DEED_NUMBER')}
                  name="deedNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.deedNumber}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.notaryName && errors.notaryName)}
                  fullWidth
                  margin="dense"
                  helperText={touched.notaryName && t(errors.notaryName)}
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.NOTARY_NAME')}
                  name="notaryName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.notaryName}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.notaryPracticeNumber && errors.notaryPracticeNumber
                  )}
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.notaryPracticeNumber &&
                    t(errors.notaryPracticeNumber)
                  }
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.NOTARY_PRACTICE_NUMBER')}
                  name="notaryPracticeNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.notaryPracticeNumber}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.notaryPracticeCity && errors.notaryPracticeCity
                  )}
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.notaryPracticeCity && t(errors.notaryPracticeCity)
                  }
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.NOTARY_PRACTICE_CITY')}
                  name="notaryPracticeCity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.notaryPracticeCity}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.registryRegistrationNumber &&
                      errors.registryRegistrationNumber
                  )}
                  fullWidth
                  margin="dense"
                  helperText={
                    touched.registryRegistrationNumber &&
                    t(errors.registryRegistrationNumber)
                  }
                  label={t(
                    'CLIENTS_VIEW.CLIENT_DETAIL.REGISTRY_REGISTRATION_NUMBER'
                  )}
                  name="registryRegistrationNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.registryRegistrationNumber}
                  variant="outlined"
                  sx={{ marginBottom: 2 }}
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

LegalClientGeneralDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  client: PropTypes.object
};

export default LegalClientGeneralDetailDialog;
