import React, { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GracePeriodValues } from 'src/utils/constants/distributors';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const getMonthDiff = diff => {
  switch (diff) {
    case 0:
      return GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS;
    case 1:
      return GracePeriodValues.ZERO;
    case 2:
      return GracePeriodValues.THIRTY;
    case 3:
      return GracePeriodValues.SIXTY;
    case 4:
      return GracePeriodValues.NINETY;
    default:
      return GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS;
  }
};

const StepFour = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const { t } = useTranslation();

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <DatePicker
                label={t('CONTRACT_VIEW.SIGNATURE_DATE')}
                inputFormat="DD/MM/YYYY"
                value={values.startDate}
                margin="startDate"
                onChange={newValue => {
                  setFieldValue('startDate', moment(newValue));
                  setFieldValue(
                    'firstRentDate',
                    moment(newValue).add(values.gracePeriod + 30, 'days')
                  );
                  setFieldValue(
                    'endDate',
                    moment(newValue)
                      .add(values.gracePeriod + 30, 'days')
                      .add(data.term - 1, 'months')
                  );
                }}
                error={Boolean(touched.startDate && errors.startDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={touched.startDate && t(errors.startDate)}
                  />
                )}
              />

              <DatePicker
                label={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
                value={values.firstRentDate}
                margin="firstRentDate"
                inputFormat="DD/MM/YYYY"
                maxDate={moment().add(4, 'months')}
                minDate={values.startDate}
                onChange={newValue => {
                  setFieldValue('firstRentDate', moment(newValue));
                  setFieldValue(
                    'endDate',
                    moment(newValue).add(data.term - 1, 'months')
                  );

                  const monthDiff =
                    moment(newValue).get('month') -
                    moment(values.startDate).get('month');

                  setFieldValue('gracePeriod', getMonthDiff(monthDiff));
                }}
                error={Boolean(touched.firstRentDate && errors.firstRentDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={
                      touched.firstRentDate && t(errors.firstRentDate)
                    }
                  />
                )}
              />

              <DatePicker
                label={t('CONTRACT_VIEW.LAST_RENT_DATE')}
                value={values.endDate}
                margin="endDate"
                disabled
                inputFormat="DD/MM/YYYY"
                error={Boolean(touched.endDate && errors.endDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{
                      sx: {
                        color: 'red'
                      }
                    }}
                    helperText={touched.endDate && t(errors.endDate)}
                    error={Boolean(touched.endDate && errors.endDate)}
                  />
                )}
              />
            </Box>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepFour.propTypes = {
  data: PropTypes.object,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepFour;
