import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Slide,
  TextField,
  FormControl,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'src/components/DropdownMenu';
import { Gender } from 'src/utils/constants/clients';
import { getDifferenceBetweenStates } from 'src/utils/common';
import { MxStateOptions } from 'src/utils/constants/mxState';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { countriesList } from 'src/utils/constants/countries';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClientGenderType = [
  { name: 'COMMON.MALE', key: Gender.MALE },
  { name: 'COMMON.FEMALE', key: Gender.FEMALE }
];

const PhysicalClientGeneralDetailDialog = ({
  open,
  client,
  handleClose,
  submit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(client), [client]);
  const resetRef = useRef();
  const { t } = useTranslation();

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const difference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(difference).length === 0) {
        handleClose();
        return;
      }
      submit(difference);
      resetForm(initialState);
      handleClose();
    },
    [submit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  if (!open) return null;

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.root }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-general-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('CLIENTS_VIEW.CLIENT_DETAIL.GENERAL_CLIENT_DATA')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleOuterSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.rfc && errors.rfc)}
                  fullWidth
                  helperText={touched.rfc && t(errors.rfc)}
                  label={t('COMMON.RFC')}
                  margin="normal"
                  name="rfc"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.rfc}
                  variant="outlined"
                />

                <DatePicker
                  label={t('COMMON.YEAR')}
                  value={values.birthDate}
                  onChange={newValue => {
                    setFieldValue('birthDate', moment(newValue));
                  }}
                  error={Boolean(touched.birthDate && errors.birthDate)}
                  renderInput={params => (
                    <TextField
                      InputProps={{
                        autoComplete: 'off'
                      }}
                      {...params}
                      sx={{ width: '100%', marginTop: 2, marginBottom: 1 }}
                      helperText={touched.birthDate && t(errors.birthDate)}
                    />
                  )}
                />

                <DropdownMenu
                  list={ClientGenderType}
                  value={values.sex}
                  setValue={value => setFieldValue('sex', value)}
                  label={t('CLIENTS_VIEW.CREATION_FORM.GENDER')}
                  error={Boolean(touched.sex && errors.sex)}
                  errorText={touched.sex && t(errors.sex)}
                />

                <FormControl
                  fullWidth
                  error={Boolean(touched.birthState && errors.birthState)}
                >
                  <Autocomplete
                    options={MxStateOptions}
                    getOptionLabel={option =>
                      option.name ? `${option.name}` : ''
                    }
                    label={t('CLIENTS_VIEW.CLIENT_DETAIL.BIRTH_STATE')}
                    id="birthState"
                    value={MxStateOptions.find(
                      option => option.name === values.birthState
                    )}
                    fullWidth
                    error={Boolean(touched.birthState && errors.birthState)}
                    helperText={touched.birthState && t(errors.birthState)}
                    sx={{ marginBottom: 1, marginTop: 2 }}
                    onChange={(_e, value) => {
                      setFieldValue('birthState', value ? value.name : '');
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Autocomplete
                    options={countriesList}
                    id="natiolanity"
                    getOptionLabel={option => {
                      return option ? t(option.name) : '';
                    }}
                    value={
                      countriesList.find(
                        country => country.key === values.nationality
                      ) ?? ''
                    }
                    keepMounted
                    fullWidth
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    onChange={(_e, value) => {
                      setFieldValue('nationality', value.key ?? null);
                    }}
                    helperText={touched.nationality && t(errors.nationality)}
                    label={t('COMMON.COUNTRY_OF_BIRTH')}
                    error={Boolean(touched.nationality && errors.nationality)}
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(touched.curp && errors.curp)}
                  fullWidth
                  helperText={touched.curp && t(errors.curp)}
                  label="CURP"
                  margin="normal"
                  name="curp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.curp}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off'
                  }}
                  error={Boolean(
                    touched.identificationNumber && errors.identificationNumber
                  )}
                  fullWidth
                  helperText={
                    touched.identificationNumber &&
                    t(errors.identificationNumber)
                  }
                  label={t('CLIENTS_VIEW.CLIENT_DETAIL.IDENTIFICATION_NUMBER')}
                  margin="normal"
                  name="identificationNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.identificationNumber}
                  variant="outlined"
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button
                      className={clsx(classes.label, classes.buttons)}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

PhysicalClientGeneralDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  client: PropTypes.object
};

export default PhysicalClientGeneralDetailDialog;
