const ClientStatus = {
  ACTIVE: 'ACTIVE',
  PIPELINE: 'PIPELINE',
  LEFT: 'LEFT',
  PROSPECT: 'PROSPECT',
  REJECTED: 'REJECTED'
};

export const PersonType = {
  PHYSICAL: 'PHYSICAL',
  LEGAL: 'LEGAL',
  AVAL: 'AVAL',
  INVESTOR: 'INVESTOR'
};

export const Kinship = {
  FATHER: 'FATHER',
  MOTHER: 'MOTHER',
  SON: 'SON',
  DAUGHTER: 'DAUGHTER',
  SIBLING: 'SIBLING',
  HUSBAND: 'HUSBAND',
  WIFE: 'WIFE'
};

export const AvalRole = {
  LEGAL_REPRESENTATIVE: 'LEGAL_REPRESENTATIVE',
  DEPOSITARY: 'DEPOSITARY',
  SOLIDARY_OBLIGEE: 'SOLIDARY_OBLIGEE'
};

export const CommonActions = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  CHANGE_STATUS: 'CHANGE_STATUS',
  RESEND_VALIDATION: 'RESEND_VALIDATION'
};

export const CfdiActions = {
  DOWNLOAD_CFDI: 'DOWNLOAD_CFDI',
  DOWNLOAD: 'DOWNLOAD',
};

export const ClientMenuActions = {
  REASSIGN: 'REASSIGN'
};

export const NufiMenuActions = {
  VALIDATE_FIELD: 'VALIDATE_FIELD'
};

export const AvalListMenuActions = {
  ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE: 'ASSIGN_DEFAULT_LEGAL_REPRESENTATIVE'
};

export const defaultMenuOptions = [
  {
    name: CommonActions.EDIT,
    label: `ENUMS.ADMINISTRATIVE_ACTIONS.${CommonActions.EDIT}`
  },
  {
    name: CommonActions.DELETE,
    label: `ENUMS.ADMINISTRATIVE_ACTIONS.${CommonActions.DELETE}`
  }
];

export const DocumentType = {
  IFE_FRONT: 'IFE_FRONT',
  IFE_BACK: 'IFE_BACK',
  PASSPORT: 'PASSPORT',
  RFC_PROOF: 'RFC_PROOF',
  RFC_COMPLIANCE: 'RFC_COMPLIANCE',
  LAST_TAX_RETURN: 'LAST_TAX_RETURN',
  CONSTITUTIVE_ACT: 'CONSTITUTIVE_ACT',
  RPP_REGISTER: 'RPP_REGISTER',
  EEFF_LAST_QUARTER: 'EEFF_LAST_QUARTER',
  EEFF_LAST_PERIOD: 'EEFF_LAST_PERIOD',
  CONTRACT: 'CONTRACT',
  PROMISSORY_NOTE: 'PROMISSORY_NOTE',
  MOFFIN_BUREAU_REPORT: 'MOFFIN_BUREAU_REPORT',
  TAX_STATUS_DOC: 'TAX_STATUS_DOC',
  ADDRESS_PROOF: 'ADDRESS_PROOF',
  BANK_STATEMENT: 'BANK_STATEMENT',
  OTHER: 'OTHER',
  AMENDING_AGREEMENT: 'AMENDING_AGREEMENT',
  BANK_COVER: 'BANK_COVER',
  KYC: 'KYC',
  TITLE: 'TITLE',
  RECORD: 'RECORD',
  ACCOUNT_STATEMENT: 'ACCOUNT_STATEMENT',
  INVOICE: 'INVOICE',
  PROOF_OF_PAYMENT: 'PROOF_OF_PAYMENT',
  QUOTE: 'QUOTE'
};

export const DocumentOwner = {
  CLIENT: 'CLIENT',
  AVAL: 'AVAL',
  CONTRACT: 'CONTRACT',
  PROMISSORY_NOTE: 'PROMISSORY_NOTE',
  INVESTOR: 'INVESTOR',
  INVESTMENT: 'INVESTMENT'
};

export const validationSection = {
  JUDICIAL_RECORDS: 'JUDICIAL_RECORDS',
  VALIDATE_IDENTITY: 'VALIDATE_IDENTITY',
  VALIDATE_IDENTIFICATION: 'VALIDATE_IDENTIFICATION',
  CONTACT_INFORMATION: 'CONTACT_INFORMATION'
};

export const ValidationType = {
  physicalPerson: {
    JUDICIAL_RECORDS: {
      criminalRecords: 'criminalRecords'
    },
    VALIDATE_IDENTITY: {
      curp: 'curp',
      rfc: 'rfc',
      rug: 'rug',
      professionalCard: 'professionalCard',
      blackList: 'blackList',
      sat69: 'sat69'
    },
    VALIDATE_IDENTIFICATION: {
      ine: 'ine'
    },
    CONTACT_INFORMATION: {
      email: 'email',
      phone: 'phone'
    }
  },
  legalPerson: {
    VALIDATE_IDENTITY: {
      rfc: 'rfc',
      siger: 'siger',
      rug: 'rug',
      ofac: 'ofac',
      sat69: 'sat69'
    },
    JUDICIAL_RECORDS: {
      criminalRecords: 'criminalRecords'
    },
    VALIDATE_IDENTIFICATION: {}
  }
};

export const Gender = {
  MALE: 'H',
  FEMALE: 'M'
};

export const MoffinScoreRange = {
  FAIL: 'FAIL',
  WARNING: 'WARNING',
  PASS: 'PASS'
};

export const SatWsCredentialStatus = {
  PENDING: 'pending',
  VALID: 'valid',
  INVALID: 'invalid',
  DEACTIVATED: 'deactivated',
  ERROR: 'error'
};

export const SatWsClientChangeParameter = {
  ADDRESS: 'ADDRESS',
  TAX_RETURN: 'TAX_RETURN',
  TAX_COMPLIANCE_DOC: 'TAX_COMPLIANCE_DOC',
  TAX_COMPLIANCE_STATUS: 'TAX_COMPLIANCE_STATUS',
  LEGAL_NAME: 'LEGAL_NAME',
  GOVERNMENT_PROVIDER_STATUS: 'GOVERNMENT_PROVIDER_STATUS',
  TAX_SYSTEM: 'TAX_SYSTEM',
  TAX_STATUS_DOC: 'TAX_STATUS_DOC'
};

export const TaxComplianceStatus = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NO_OBLIGATIONS: 'NO_OBLIGATIONS'
};

export const clientDetailTabsIndex = {
  GENERAL: 'general',
  AVAL_DETAIL: 'aval',
  ADMINISTRATIVE_DETAIL: 'administratives',
  ACCOUNTING_STATE: 'accounting',
  CONTRACTS_DETAIL: 'contracts',
  NUFI_VALIDATION: 'identity',
  MOFFIN_VALIDATION: 'buro',
  SAT_WS_VALIDATION: 'billing',
  QUOTES_DETAIL: 'quotes'
};

export const BillingTabsIndex = {
  SAT_INFORMATION: 'sat_information',
  HISTORY: 'history',
  BANK_ACCOUNT: 'bank_account'
};

export const BuroDetailTabsIndex = {
  VALIDATION: 'validation',
  HISTORY: 'history'
};

export const InstanceType = {
  AVAL: 'AVAL',
  CLIENT: 'CLIENT'
}

export default ClientStatus;
