import moment from 'moment-timezone';

export default values => ({
  industry: values.industry ?? '',
  birthDate: values.birthDate ?? moment(),
  rfc: values.rfc ?? '',
  deedNumber: values.deedNumber ?? '',
  notaryName: values.notaryName ?? '',
  notaryPracticeNumber: values.notaryPracticeNumber ?? '',
  notaryPracticeCity: values.notaryPracticeCity ?? '',
  registryRegistrationNumber: values.registryRegistrationNumber ?? ''
});
