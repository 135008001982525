import { CancelOutlined, Send } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContractStatus, {
  ContractActionsOptions
} from 'src/utils/constants/contracts';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTIVATE_AUTOMATIC_BILLING,
  CANCEL_CONTRACT,
  DEACTIVATE_AUTOMATIC_BILLING,
  SEND_TO_DIGITAL_SIGN_CONTRACT,
  SIGN_CONTRACT_MANUALLY,
  activateAutomaticBilling,
  desactivateAutomaticBilling,
  sendToCancelContract,
  sendToSingDocuments,
  signContractManuallyAction
} from 'src/store/actions/contractActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { selectAuthRole } from 'src/store/selectors/authSelectors';
import UserType from 'src/utils/constants/auth';
import MenuButton from 'src/components/MenuButton/MenuButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import PaymentIcon from '@mui/icons-material/Payment';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import {
  RESEND_SIGN_EMAIL,
  resendSignEmailAction
} from 'src/store/actions/commonActions';
import AlertMessage from 'src/components/Alert/Alert';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import useStyles from '../styles';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import SignContractManuallyDialog from './SignContractManuallyDialog';

export const ContractActionMenuOptions = [
  {
    label: 'CONTRACT_VIEW.CONTRACT_DETAIL.SEND_TO_DIGITAL_SIGN',
    key: ContractActionsOptions.SEND_TO_DIGITAL_SIGN,
    icon: <Send />
  },
  {
    label: 'CONTRACT_VIEW.CONTRACT_DETAIL.SIGN_MANUALLY',
    key: ContractActionsOptions.SIGN_MANUALLY,
    icon: <Send />
  },
  {
    label: 'COMMON.RESEND_SIGNING_URL',
    key: ContractActionsOptions.RESEND_TO_SIGN,
    icon: <RefreshIcon />
  },
  {
    label: 'CONTRACT_VIEW.CONTRACT_DETAIL.CANCEL_CONTRACT',
    key: ContractActionsOptions.CANCEL_CONTRACT,
    icon: <CancelOutlined color="error" />
  },
  {
    label: 'COMMON.ACTIVATE_AUTOMATIC_BILLING',
    key: ContractActionsOptions.ACTIVATE_AUTOMATIC_BILLING,
    icon: <PaymentIcon />
  },
  {
    label: 'COMMON.DEACTIVATE_AUTOMATIC_BILLING',
    key: ContractActionsOptions.DESACTIVATE_AUTOMATIC_BILLING,
    icon: <CreditCardOffIcon />
  }
];

const DetailHeader = ({ contract }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const openAlert = !contract.automaticBilling;
  const role = useSelector(selectAuthRole);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openCancelContract, setOpenCancelContract] = useState(false);
  const [openSignManuallyDialog, setOpenSignManuallyDialog] = useState(false);
  const [openSendToDigitalSign, setOpenSendToDigitalSign] = useState(false);

  const handleOpenSignManuallyDialog = () => {
    setOpenSignManuallyDialog(true);
  };

  const handleCloseSignManuallyDialog = () => {
    setOpenSignManuallyDialog(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenCancelContract(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenCancelContract(true);
  };

  const handleOpenSendToDigitalSign = () => {
    setOpenSendToDigitalSign(true);
  }

  const handleCloseSendToDigitalSign = () => {
    setOpenSendToDigitalSign(false);
  }

  const isLoadingAction = useSelector(
    isLoadingSelector([
      SEND_TO_DIGITAL_SIGN_CONTRACT.action,
      SIGN_CONTRACT_MANUALLY.action,
      CANCEL_CONTRACT.action,
      RESEND_SIGN_EMAIL.action,
      ACTIVATE_AUTOMATIC_BILLING.action,
      DEACTIVATE_AUTOMATIC_BILLING.action
    ])
  );

  const handleSendToSign = useCallback(() => {
    dispatch(sendToSingDocuments(id));
    setOpenSendToDigitalSign(false);
  }, []);

  const handleContractManualSigning = useCallback(values => {
    dispatch(signContractManuallyAction(id, values));
  }, []);

  const handleCancelContract = useCallback(() => {
    dispatch(sendToCancelContract(id));
    handleCloseDeleteModal();
  }, []);

  const handleResendSignEmail = useCallback(() => {
    dispatch(resendSignEmailAction(contract.weetrustDocId));
  }, [contract]);

  const handleContractAutomaticBillingActivation = useCallback(() => {
    dispatch(activateAutomaticBilling(contract.id));
  }, []);

  const handleContractAutomaticBillingDeactivation = useCallback(() => {
    dispatch(desactivateAutomaticBilling(contract.id));
  }, []);

  const selectContractActionsFromStatus = () => {
    let actions = [];

    switch (contract.status) {
      case ContractStatus.DRAFT:
        actions = ContractActionMenuOptions.filter(item =>
          [
            ContractActionsOptions.SEND_TO_DIGITAL_SIGN,
            ContractActionsOptions.CANCEL_CONTRACT,
            ContractActionsOptions.SIGN_MANUALLY
          ].includes(item.key)
        );
        break;
      case ContractStatus.PENDING_SIGNATURE:
        actions = ContractActionMenuOptions.filter(item => {
          return [
            ContractActionsOptions.RESEND_TO_SIGN,
            ContractActionsOptions.CANCEL_CONTRACT
          ].includes(item.key);
        });
        break;
      case ContractStatus.IN_PROGRESS:
        if (contract.automaticBilling) {
          actions.push(
            ContractActionMenuOptions.find(
              item =>
                item.key ===
                ContractActionsOptions.DESACTIVATE_AUTOMATIC_BILLING
            )
          );
        }
        if (!contract.automaticBilling) {
          actions.push(
            ContractActionMenuOptions.find(
              item =>
                item.key === ContractActionsOptions.ACTIVATE_AUTOMATIC_BILLING
            )
          );
        }
        if (role === UserType.SUPER_ADMIN) {
          actions.push(
            ContractActionMenuOptions.find(
              item => item.key === ContractActionsOptions.CANCEL_CONTRACT
            )
          );
        }
        break;
      default:
        break;
    }

    return actions;
  };

  const handleContractAction = action => {
    switch (action) {
      case ContractActionsOptions.SEND_TO_DIGITAL_SIGN:
        handleOpenSendToDigitalSign();
        break;
      case ContractActionsOptions.SIGN_MANUALLY:
        handleOpenSignManuallyDialog();
        break;
      case ContractActionsOptions.CANCEL_CONTRACT:
        handleOpenDeleteModal();
        break;
      case ContractActionsOptions.RESEND_TO_SIGN:
        handleResendSignEmail();
        break;
      case ContractActionsOptions.ACTIVATE_AUTOMATIC_BILLING:
        handleContractAutomaticBillingActivation();
        break;
      case ContractActionsOptions.DESACTIVATE_AUTOMATIC_BILLING:
        handleContractAutomaticBillingDeactivation();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        pb={4}
        flex
        justifyContent="space-between"
      >
        <Grid
          item
          className={classes.transaction}
          lg={3}
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '23.6%'
            }
          }}
        >
          <Box className={classes.transactionIcon}>
            <MoneyIcon fill="#28A745" />
          </Box>
          <Box>
            <Typography
              style={{ marginBottom: 10 }}
              color="textPrimary"
              component="p"
              variant="body2"
            >
              {t('COMMON.RENT')}
            </Typography>
            <Typography color="primary" component="h2" variant="h2">
              {formatAmountWithDecimals(contract.rent)}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          className={classes.actionsContainer}
          lg={6}
          flex
          justifyContent="flex-end"
        >
          {contract.status !== ContractStatus.ENDED && (
            <MenuButton
              label={t('COMMON.CONTRACT_ACTIONS')}
              isLoading={isLoadingAction}
              disabled={isLoadingAction || contract.status === ContractStatus.CANCELLED}
              options={selectContractActionsFromStatus()}
              handleClickOption={handleContractAction}
              styles={{ width: 300 }}

            />
          )}

          <SignContractManuallyDialog
            open={openSignManuallyDialog}
            handleClose={handleCloseSignManuallyDialog}
            submit={handleContractManualSigning}
          />

          <DeleteConfirmationDialog
            open={openCancelContract}
            handleClose={handleCloseDeleteModal}
            handleSubmit={handleCancelContract}
            contractStatus={contract.status}
          />

          <ConfirmationDialog
            open={openSendToDigitalSign}
            handleClose={handleCloseSendToDigitalSign}
            onSubmit={handleSendToSign}
            title={t('CONTRACT_VIEW.CONTRACT_DETAIL.SEND_TO_DIGITAL_SIGN')}
            description={t('CONTRACT_VIEW.CONTRACT_DETAIL.SEND_TO_DIGITAL_SIGN_DESCRIPTION')}
          />
        </Grid>
        <Grid item xs={12} sx={!openAlert && { display: 'none' }} marginTop={4}>
          <AlertMessage
            open={openAlert}
            handleClose={() => {}}
            severity={AlertSeverity.WARNING}
            title="COMMON.WARNING"
            text="COMMON.CONTRACT_AUTOMATIC_BILLING_DEACTIVATED"
            hideCloseIcon
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailHeader;

DetailHeader.propTypes = {
  contract: PropTypes.object.isRequired
};
