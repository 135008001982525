import moment from 'moment-timezone';
import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getTransactionById = serviceBase({
  request: ({ id, options }) =>
    axiosClient.get(`/transaction/client/${id}`, { params: options }),
  retry: 0
});

export const getAllTransactions = serviceBase({
  request: options =>
    axiosClient.get('/transaction', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    })
});

export const createTransaction = serviceBase({
  request: values => axiosClient.post('/transaction', values)
});

export const getInvoiceDocumentById = serviceBase({
  request: id =>
    axiosClient.get(`/transaction/invoice/${id}`, { responseType: 'blob' })
});

export const downloadInvoiceById = serviceBase({
  request: id =>
    axiosClient.get(`transaction/invoice/${id}`, { responseType: 'blob' }),
  transformResponse: res => {
    try {
      const filename = 'Factura.pdf';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  }
});

export const getMovements = serviceBase({
  request: options =>
    axiosClient.get('/payment/movements/', { params: options })
});

export const downloadExportedDistributors = serviceBase({
  request: options =>
    axiosClient.get('/transaction/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = `Transacciones - ${moment(
        res.config.params.afterDate
      ).format('MMMM-YYYY')}.xlsx`;
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      return a.click();
    } catch (error) {
      return error;
    }
  },
  retry: 0
});

export const assignTransctionToEquipment = serviceBase({
  request: values => axiosClient.post('/transaction/assign-equipment', values)
});
